import React, { useEffect, useLayoutEffect, useState } from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/react"

import Layout from "~components/layout"
import SEO from "~components/seo"
import Card from "~components/card"
import RichText from "~components/richText"
import styled from "@emotion/styled"
import { Grid, breakpoints } from "~styles/global"
import { HeaderTitle, useFilter, useSiteState } from "~context/siteContext"
import useSiteSettings from "~utils/useSiteSettings"
import { DateTime } from 'luxon'
import Masonry from 'react-masonry-css'
import sanityClient from '@sanity/client'
import { useInView } from 'react-intersection-observer'

const { tablet } = breakpoints


const IndexPage = ({ data, pageContext }) => {
  const [docs, setDocs] = useState([])
  const [allDocs, setAllDocs] = useState([])
  const [windowWidth, setWindowWidth] = useState()
  const { sort, show, setShow, searchTerm  } = useFilter()
  const { type, pages, seo, customText, customTitle, title } = pageContext
	const [numPosts, setNumPosts] = useState(50)
	const [inViewRef, inView] = useInView({threshold: 0,})

  const { siteTitle, siteMetaDescription, siteSocialImage } = useSiteSettings()

  const [siteState, setSiteState] = useSiteState()

  const client = sanityClient({
    projectId: process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.GATSBY_SANITY_DATASET,
    token: process.env.GATSBY_SANITY_API_TOKEN,
    apiVersion: '2021-07-22',
    useCdn: false
  })

  const handleResize = () => {
    setWindowWidth(window.innerWidth)
  }

	const setLoading = val => {
		setSiteState(prevState => ({
			...prevState,
			loading: val,
		}))
	}

	useEffect(() => {
		if (inView) {
			setNumPosts(numPosts + 50)
		}
	}, [inView])

  useLayoutEffect(() => {
      handleResize()
      window.addEventListener('resize', handleResize)
      return () => window.removeEventListener('resize', handleResize)
    }, [])

  function generateQuery(limit){
      let query = `*[!(_id in path("drafts.**")) `
  
      // add relevant to the filter
      if(type === 'home'){
        query += `&& (_type in ['article', 'artist', 'curator', 'event', 'program', 'textDoc', 'work', 'writer'])`
      } else if(type){
        query += `&& '${type}' in content.pages`
      }

      // if(type === 'collectionPage'){
      //   query += `&& (_type in ['article', 'artist', 'curator', 'event', 'program', 'textDoc', 'work', 'writer'])`
      // }

      if(show){
          query += ` && _type == '${show}'`
      }

      if(searchTerm?.length){
          const words = searchTerm.split(' ')
          query += `&& [title, content.seo.metaDescription] match ${JSON.stringify(words)}`
        }

			let order
      if(sort === 'date'){
        order =  `order(content.date desc)`
      } else if (sort === 'title') {
        order =  `order(title asc)`
      } else {
        order =  `order((content.pinned == true) desc, content.date desc)`
      }

			query += `]`

			query += `| ${order}`

			query += `[${(limit ?? numPosts) - 50}...${limit ?? numPosts}]`
  
      query += `
      { ...,
        'content': {
          ...content,
          'artists': content.artists[]->,
          'related': content.related[]->, 
          'elements': content.elements[]->,
          'pinned': content.pinned == true
        },    
      }`
  
      return query
  }

	useEffect(() => {
		setNumPosts(50)
		let query = generateQuery(50)
		setLoading(true)
		client.fetch(query).then(posts => {
			console.log(posts)
			setDocs(posts)
			setLoading(false)
			window.scrollTo(0, 0)
		})
	}, [sort, show, searchTerm])


  useEffect(() => {
			if(numPosts <= 50) return
      let query = generateQuery()
      setLoading(true)
      client.fetch(query).then(posts => {
				setDocs([...docs, ...posts])
        setLoading(false)
      })

  }, [numPosts])
	

  // useEffect(() => {

  //   const all = pagesWithPinnedPages?.sort((a, b) => {
  //     const aDate = a.node._rawContent?.date ? new Date(a.node._rawContent?.date).getTime() : 0
  //     const bDate = b.node._rawContent?.date ? new Date(b.node._rawContent?.date).getTime() : 0
  //     return bDate - aDate
  //   })

  //   setLoading(false)

  //   // setAllDocs(all)
  //   if(type !== 'home'){
  //     setDocs(all)
  //     setShow(null)
  //   }

  // }, [])

  const breakpointColumnsObj = {
    default: 4,
    1024: 2,
    768: 1,
  };

  return (
    <Layout>
      <SEO
      title={title}
      image={seo?.socialImage?.asset?.url}
      description={seo?.metaDescription}
      />
      <HeaderTitle title={pageContext.title ?? siteTitle} />
      {type === `collectionPage` && 
      <div css={css`    
        margin: 1rem 0.5rem 1.5rem;
        ${tablet}{
          margin: 1rem 1rem 1.5rem;
        }
      `}>
        {customTitle && <h1 className="mb-1">{customTitle}</h1>}
        {customText && <div className="mb-1"><RichText content={customText}/></div> } 
      </div>
      }
      { docs?.length > 0 &&
      <div css={css`
        margin: 0 -0.5rem;
        ${tablet}{
          margin: 0;
        }
      `}>
        <Masonry 
          breakpointCols={breakpointColumnsObj}
          className="m-grid"
          columnClassName="m-grid_column"
        >
        {docs.map(doc => <Card doc={doc} key={doc.slug.current + doc._type}/>)}
        </Masonry>
      </div>}
			{docs.length >= 50 && docs.length % 50 === 0 &&
				<div ref={inViewRef} />
			}
    </Layout>
  )
}

export default IndexPage
