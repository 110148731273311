import { graphql, useStaticQuery } from "gatsby"

const useSiteSettings = () => {

  const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings(_id: {regex: "/(drafts\\\\.)?siteSettings/"}) {
        _rawSocialImage(resolveReferences: {maxDepth: 1})
        metaDescription
        siteTitle
      }
    }
    `)

  const settings = siteSettings.sanitySiteSettings

  return {
    siteTitle: settings.siteTitle,
    siteMetaDescription: settings.metaDescription,
    siteSocialImage: settings._rawSocialImage
  }
}

export default useSiteSettings
